<template>
  <DefaultTemplate>
    <div class="box-card" id="user-personal" v-if="loading">
      <el-row :gutter="20">
        <el-col :span="24">
          <el-breadcrumb
            separator-class="el-icon-arrow-right"
            class="set-breadcrumb"
          >
            <el-breadcrumb-item :to="{ path: '/user/detail/' + criterionId }"
              >จัดการข้อมูลผู้ประกอบการ</el-breadcrumb-item
            >
            <el-breadcrumb-item>ข้อมูลบริษัท</el-breadcrumb-item>
          </el-breadcrumb>
        </el-col>
        <el-col :span="24" class="mg-b-4">
          <p class="text-center font-30 font-weight-500">ข้อมูลบริษัท</p>
        </el-col>
        <div class="is-flex js-center w-100 flex-column">
          <el-col :span="24" :sm="16" :md="20" :lg="16" class="mg-x-auto">
            <el-form
              label-position="top"
              label-width="100px"
              :model="dataCompany"
            >
              <el-form-item label="ชื่อบริษัท">
                <el-input
                  v-model="dataCompany.companyName"
                  :disabled="true"
                ></el-input>
              </el-form-item>
              <el-form-item label="เลขนิติบุคคล">
                <el-input
                  v-model="dataCompany.juristicId"
                  :disabled="true"
                ></el-input>
              </el-form-item>
              <el-form-item label="ลักษณะธุรกิจ">
                <el-select
                  v-model="dataCompany.businessType"
                  :disabled="true"
                  class="w-100"
                  placeholder="กรุณาเลือกลักษณะธุรกิจ"
                >
                  <el-option
                    v-for="item in optionBusinessType"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="ประเภทของอุตสาหกรรม">
                <el-select
                  v-model="dataCompany.cateIndustryType"
                  :disabled="true"
                  class="w-100"
                  placeholder="กรุณาเลือกประเภทของอุตสาหกรรม"
                >
                  <el-option
                    v-for="item in optionIndustryType"
                    :key="item.id"
                    :label="item.label"
                    :value="item.id"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item
                label="ประเภทย่อยของอุตสาหกรรม"
                v-if="dataCompany.cateIndustryType != 0"
              >
                <el-select
                  v-model="dataCompany.industryType"
                  :disabled="true"
                  class="w-100"
                  placeholder="กรุณาเลือกประเภทย่อยของอุตสาหกรรม"
                >
                  <el-option
                    v-for="item in subIndustry"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="สมาชิกของหน่วยงานใด">
                <el-input
                  v-model="dataCompany.memberOf"
                  :disabled="true"
                ></el-input>
              </el-form-item>
              <!-- <el-form-item label="การจ้างงาน">
                <el-select
                  v-model="dataCompany.employment"
                  :disabled="true"
                  class="w-100"
                  placeholder="กรุณาเลือกการจ้างงาน"
                >
                  <el-option
                    v-for="item in optionEmployment"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </el-form-item> -->
              <el-form-item label="รายได้ประจำปีล่าสุดของบริษัท (บาท)">
                <div class="el-input is-disabled">
                  <vue-numeric
                    class="el-input__inner"
                    :disabled="true"
                    :precision="2"
                    v-model="dataCompany.latestAnnualRevenues"
                  ></vue-numeric>
                </div>
              </el-form-item>
              <el-form-item label="ประเภทของผู้ประกอบการ">
                <el-select
                  v-model="dataCompany.operatorType"
                  class="w-100"
                  placeholder="กรุณาเลือกประเภทของผู้ประกอบการ"
                  :disabled="true"
                >
                  <el-option
                    v-for="item in optionOperatorType"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <div v-if="dataCompany.operatorType == 3">
                <el-form-item label="ประสบการณ์ในการส่งออก">
                  <el-select
                    v-model="dataCompany.exportExperience"
                    class="w-100"
                    :disabled="true"
                    placeholder="กรุณาเลือกประสบการณ์ในการส่งออก"
                  >
                    <el-option
                      v-for="item in optionExportExperience"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="สัดส่วนในการส่งออก">
                  <el-select
                    v-model="dataCompany.exportRatio"
                    class="w-100"
                    :disabled="true"
                    placeholder="กรุณาเลือกสัดส่วนในการส่งออก"
                  >
                    <el-option
                      v-for="item in optionExportRatio"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item
                  label="ความสม่ำเสมอของการสั่งซื้อจากผู้ซื้อต่างประเทศรายเดิม"
                >
                  <el-select
                    v-model="dataCompany.orderConsistency"
                    class="w-100"
                    :disabled="true"
                    placeholder="กรุณาเลือกความสม่ำเสมอของการสั่งซื้อจากผู้ซื้อต่างประเทศรายเดิม"
                  >
                    <el-option
                      v-for="item in optionOrderConsistency"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="ความถี่ในการส่งออกโดยเฉลี่ย">
                  <el-select
                    v-model="dataCompany.exportFrequency"
                    class="w-100"
                    :disabled="true"
                    placeholder="กรุณาเลือกความถี่ในการส่งออกโดยเฉลี่ย"
                  >
                    <el-option
                      v-for="item in optionExportFrequency"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="วงเงินค้าขายต่อ Shipment โดยเฉลี่ย">
                  <div class="el-input is-disabled">
                    <el-input
                      type="text"
                      :value="returnData(dataCompany.shipment, 'shipment')"
                      :disabled="true"
                    />
                    <!-- <vue-numeric
                      class="el-input__inner"
                      :disabled="true"
                      :precision="2"
                      v-model="dataCompany.shipment"
                    ></vue-numeric> -->
                  </div>
                </el-form-item>
                <el-form-item label="เทอมการชำระเงิน">
                  <el-select
                    v-model="dataCompany.paymentTerm"
                    class="w-100"
                    :disabled="true"
                    placeholder="กรุณาเลือกเทอมการชำระเงิน"
                  >
                    <el-option
                      v-for="item in optionPaymentTerm"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="มูลค่าการส่งออก">
                  <div class="el-input is-disabled">
                    <el-input
                      type="text"
                      :value="
                        returnData(dataCompany.exportValue, 'exportValue')
                      "
                      :disabled="true"
                    />
                    <!-- <vue-numeric
                      class="el-input__inner"
                      :disabled="true"
                      :precision="2"
                      v-model="dataCompany.exportValue"
                    ></vue-numeric> -->
                  </div>
                </el-form-item>
                <el-form-item label="จำนวนประเทศที่ทำการส่งออก">
                  <el-select
                    v-model="dataCompany.exportCountry"
                    class="w-100"
                    :disabled="true"
                    placeholder="กรุณาเลือกจำนวนประเทศที่ทำการส่งออก"
                  >
                    <el-option
                      v-for="item in optionExportCountry"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item
                  label="ร้อยละที่คาดหวังว่าจะส่งออกได้/ ส่งออกได้เพิ่มขึ้น"
                >
                  <el-select
                    v-model="dataCompany.expectedPercentage"
                    class="w-100"
                    :disabled="true"
                    placeholder="กรุณาเลือกร้อยละที่คาดหวังว่าจะส่งออกได้/ ส่งออกได้เพิ่มขึ้น"
                  >
                    <el-option
                      v-for="item in optionExpectedPercentage"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </div>

              <el-form-item
                label="มีการผลิต หรือ ขายให้กับผู้ส่งออกโดยตรงหรือไม่"
                v-else-if="dataCompany.operatorType == 1"
              >
                <el-select
                  v-model="dataCompany.produceOrSoldExport"
                  :disabled="true"
                  class="w-100"
                >
                  <el-option
                    v-for="item in optionExportPlan"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item
                label="ภายในปีนี้มีแผนจะเป็นผู้ส่งออกโดยตรงหรือไม่"
                v-if="dataCompany.operatorType == 1"
              >
                <el-select
                  v-model="dataCompany.exportPlan"
                  :disabled="true"
                  class="w-100"
                >
                  <el-option
                    v-for="item in optionExportPlan"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </el-form-item>

              <el-form-item
                label="ร้อยละที่คาดหวังว่าจะส่งออกได้/ ส่งออกได้เพิ่มขึ้น"
                v-if="dataCompany.exportPlan == 1"
              >
                <el-select
                  v-model="dataCompany.expectedPercentage"
                  class="w-100"
                  :disabled="true"
                  placeholder="กรุณาเลือกร้อยละที่คาดหวังว่าจะส่งออกได้/ ส่งออกได้เพิ่มขึ้น"
                >
                  <el-option
                    v-for="item in optionExpectedPercentage"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-form>
          </el-col>
        </div>
      </el-row>
    </div>
  </DefaultTemplate>
</template>

<script>
import DefaultTemplate from "@/template/DefaultTemplate";
import { HTTP } from "@/service/axios";
import { mapState } from "vuex";

export default {
  components: {
    DefaultTemplate,
  },
  data() {
    return {
      criterionId: this.$route.params.criterionId,
      page: 1,
      loading: false,
      dataCompany: {
        nameCompany: "ทรานส์สปอร์ต",
        juristicNumber: "80123456789",
        typeOfBusiness: "ธุรกิจบริการ",
        typeOfIndustry: "อาหาร/เครื่องดื่ม",
        industrySubCategories: "อาหารสำเร็จรูป",
        memberOfAgency: "EXAC",
        employment: "51 - 100 คน",
        latestIncome: "10,000,000 บาท",
        operatorType: "ผู้ผลิตเพื่อผู้ส่งออก",
        exportExperience: "1-2 ปี",
        exportProportion: "1%-20% ของยอดขายทั้งหมด",
        orderRegularity: "เป็นครั้งคราว เป็นระยะเวลามากกว่า 2 ปี",
        exportFrequencyAvg: "มากกว่า 1 ครั้งต่อไตรมาส",
        shipment: "เกิน 0.5 ล้านบาท",
        paymentTerm: "120 วัน",
        exportValue: "ไม่เกิน 200 ล้านบาท",
        exportingCount: "1-2 ประเทศ",
        exportablePercentage: "ร้อยละ 16 - 20",
        planToExporter: "มีแผน",
      },
    };
  },
  computed: {
    ...mapState({
      pageActive: (state) => state.pageActive,
      _token: (state) => state._token,
      optionBusinessType: (state) => state.userOption.businessType,
      optionIndustryType: (state) => state.industryType,
      optionEmployment: (state) => state.userOption.employment,
      optionOperatorType: (state) => state.userOption.operatorType,
      optionExportPlan: (state) => state.userOption.exportPlan,
      optionExportExperience: (state) => state.userOption.exportExperience,
      optionExportRatio: (state) => state.userOption.exportRatio,
      optionOrderConsistency: (state) => state.userOption.orderConsistency,
      optionExportFrequency: (state) => state.userOption.exportFrequency,
      optionPaymentTerm: (state) => state.userOption.paymentTerm,
      optionExportCountry: (state) => state.userOption.exportCountry,
      optionExpectedPercentage: (state) => state.userOption.expectedPercentage,
    }),
    subIndustry() {
      let filterSub = this.$store.state.industryType.filter(
        (a) =>
          this.dataCompany.cateIndustryType != null &&
          a.id === this.dataCompany.cateIndustryType
      );
      if (filterSub.length > 0) {
        return filterSub[0].subIndustry;
      } else {
        return [];
      }
    },
  },
  async mounted() {
    await this.getCompanById();
  },
  methods: {
    returnData(data, type) {
      let result = "";
      if (type == "shipment") {
        if (data > 1000000) {
          result = "มากกว่า 1.0 ล้านบาท";
        } else if (data > 500000) {
          result = "0.5-1.0 ล้านบาท";
        } else {
          result = "น้อยกว่า 0.5 ล้านบาท";
        }
      } else if ("exportValue") {
        if (data > 500000000) {
          result = "มากกว่า 500 ล้านบาท";
        } else if (data > 400000000) {
          result = "401-500 ล้านบาท";
        } else if (data > 300000000) {
          result = "301-400 ล้านบาท";
        } else if (data > 200000000) {
          result = "201-300 ล้านบาท";
        } else if (data > 100000000) {
          result = "101-200 ล้านบาท";
        } else {
          result = "ไม่เกิน 100 ล้านบาท";
        }
      }
      return result;
    },
    getCompanById() {
      HTTP.defaults.headers.common.Authorization = `Bearer ${this._token}`;
      HTTP.get(`user/company/${this.$route.params.id}`) // /user
        .then((res) => {
          if (res.data.success) {
            if (res.data.obj != null) {
              this.dataCompany = res.data.obj;
              if (this.dataCompany.type != "Channel Integration") {
                this.dataCompany.memberOf = this.dataCompany.memberOf.slice(
                  0,
                  this.dataCompany.memberOf.length - 1
                );
                this.dataCompany.exportFrequency = Number(
                  res.data.obj.exportFrequency
                );
                this.dataCompany.exportCountry = Number(
                  res.data.obj.exportCountry
                );
                this.dataCompany.produceOrSoldExport = Number(
                  res.data.obj.produceOrSoldExport
                );

                this.dataCompany.exportRatio =
                  this.dataCompany.exportRatio == 0
                    ? ""
                    : this.dataCompany.exportRatio;

                this.dataCompany.orderConsistency =
                  this.dataCompany.orderConsistency == 0
                    ? ""
                    : this.dataCompany.orderConsistency;

                this.dataCompany.exportExperience =
                  this.dataCompany.exportExperience == 0
                    ? ""
                    : this.dataCompany.exportExperience;

                this.dataCompany.orderConsistency =
                  this.dataCompany.orderConsistency == 0
                    ? ""
                    : this.dataCompany.orderConsistency;

                this.dataCompany.exportFrequency =
                  this.dataCompany.exportFrequency == 0
                    ? ""
                    : this.dataCompany.exportFrequency;
                this.dataCompany.cateIndustryType =
                  this.dataCompany.cateIndustryType == 0
                    ? ""
                    : this.dataCompany.cateIndustryType;
              } 
              this.loading = true;
            } else {
              this.$router.push("/" + this.pageActive);
              this.$message({
                message: "ไม่พบข้อมูลบริษัทนี้",
                type: "error",
                duration: 4000,
              });
            }
          }
        })
        .catch((e) => {
          if (e.response != undefined) {
            if (e.response.data.status == 401) {
              this.checkAuth();
            } else if (e.response.data.status == 422) {
              this.$message({
                message: "ไม่พบข้อมูลบริษัทนี้",
                type: "error",
                duration: 4000,
              });
              this.$route.push("/" + this.pageActive);
            } else {
              this.alertCatchError(e);
            }
          }
        });
    },
  },
};
</script>
